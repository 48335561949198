import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Input from '../../../components/FormInput';

//material icons
import DeleteIcon from '@material-ui/icons/Delete';

// material core
import {
  IconButton,
  LinearProgress,
  CircularProgress,
  Button,
  Paper,
} from '@material-ui/core';

import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

// constom items
import Dialog from '../../../components/Dialog';
import {
  createMedicalConsultation,
  deleteMedicalConsultation,
  getSingleMedicalConsultation,
  updateMedicalConsultation,
} from 'Services/MedicalConsultationService';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
  },
  formContainer: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
    margin: 16,
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 600,
    // height: 500,
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
  chipRow: {
    width: '100%',
    padding: `${theme.spacing(1)}px 0px !important`,
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  dropzoneWrapper: {
    width: '100%',
    height: 225,
  },
  dropzoneContainer: {
    width: '100%',
    height: 200,
    border: '1px dotted rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleBtnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  htmlContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0px 0 5px 0',
  },
  coverImageContainer: {
    width: '100%',
    height: 200,
    borderRadius: 4,
    marginBottom: 20,
    position: 'relative',
  },
  coverImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 4,
  },
  coverImageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  closeBtnContainer: {
    position: 'absolute',
    top: -25,
    right: -25,
  },
  noteIcon: {
    fontSize: 60,
    color: 'rgb(204, 204, 204)',
  },
  dropzoneText: {
    fontSize: 26,
    color: 'rgb(204, 204, 204)',
    margin: '5px 0px 2px 0px',
  },
  dropzoneSubText: {
    fontSize: 12,
    color: 'rgb(204, 204, 204)',
  },
  pdfContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  pdfWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  pdfTextRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '10px 0px 20px 0px',
  },
  pdfIcon: {
    fontSize: 50,
    color: '#34495e',
  },
  pdfTextCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '0px 0px 0px 10px',
  },
  pdfText: {
    width: '400px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pdfSubText: {
    width: '200px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  deleteButtonContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  imagesGridList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: '#ffffff',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imageDeleteIcon: {
    color: '#fffff',
  },
});

type FormItem = {
  name: string;
  description: string;
  lng: number | null;
  lat: number | null;
  phone: string;
  specialty: string;
  region: string;
  address: string;
};

const FORM_ITEM = {
  name: '',
  description: '',
  lng: null,
  lat: null,
  phone: '',
  specialty: '',
  region: '',
  address: '',
};

const Schema = Yup.object().shape({
  name: Yup.string().nullable().required('必填項目'),
  description: Yup.string().nullable().required('必填項目'),
  lng: Yup.number().nullable().required('必填項目'),
  lat: Yup.number().nullable().required('必填項目'),
  phone: Yup.string().nullable().required('必填項目'),
  specialty: Yup.string().nullable().required('必填項目'),
  region: Yup.string().nullable().required('必填項目'),
  address: Yup.string().nullable().required('必填項目'),
});

type Props = WithStyles<typeof styles>;
type State = {
  form: FormItem;
  loading: boolean;
  formLoading: boolean;
  medicalConsultationId: number | null;
  deleteVisible: boolean;
  deleteLoading: boolean;
};

type TParams = {
  medicalConsultationId: string;
};

class MedicalFormPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      medicalConsultationId: null,
      form: { ...FORM_ITEM },
      loading: false,
      formLoading: true,
      deleteVisible: false,
      deleteLoading: false,
    };
    this.deleteOnPress = this.deleteOnPress.bind(this);
    this.deleteOnClose = this.deleteOnClose.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
  }

  componentDidMount() {
    this.getMedicalConsultation();
  }

  async getMedicalConsultation() {
    const { match } = this.props;
    const { params } = match;
    try {
      if (params && params.medicalConsultationId) {
        const medicRes = await getSingleMedicalConsultation(
          params.medicalConsultationId,
        );
        const {
          medicalConsultationid,
          name,
          description,
          lat,
          lng,
          phone,
          specialty,
          region,
          address,
        } = medicRes;
        this.setState({
          medicalConsultationId: medicalConsultationid,
          formLoading: false,
          form: {
            name,
            description,
            lat,
            lng,
            phone,
            specialty,
            region,
            address,
          },
        });
      } else {
        this.setState({ formLoading: false });
      }
    } catch (err) {
      console.log(err);
      toast('找不到醫事顧問，請返回上一頁。');
    }
  }

  async createMedical(values) {
    const { history } = this.props;
    this.setState({ loading: true });
    const {
      name,
      description,
      lat,
      lng,
      phone,
      specialty,
      region,
      address,
    } = values;
    try {
      const params = {
        name,
        description,
        lat,
        lng,
        phone,
        specialty,
        region,
        address,
      };
      await createMedicalConsultation(params);
      this.setState({ loading: false });
      toast('新增醫事顧問成功。');
      history.push('/medical-consultation');
    } catch (err) {
      this.setState({ loading: false });
      toast('新增醫事顧問失敗，請重試。');
      console.log(err);
    }
  }

  async updateMedical(values) {
    this.setState({ loading: true });
    const {
      name,
      description,
      lat,
      lng,
      phone,
      specialty,
      region,
      address,
    } = values;
    const { medicalConsultationId } = this.state;
    try {
      const params = {
        name,
        description,
        lat,
        lng,
        phone,
        specialty,
        region,
        address,
      };
      await updateMedicalConsultation(medicalConsultationId!, params);
      this.setState({ loading: false });
      toast('編輯醫事顧問成功。');
    } catch (err) {
      this.setState({ loading: false });
      toast('編輯醫事顧問失敗，請重試。');
      console.log(err);
    }
  }

  onSubmit(values) {
    const { medicalConsultationId } = this.state;
    if (medicalConsultationId) {
      this.updateMedical(values);
    } else {
      this.createMedical(values);
    }
  }

  deleteOnPress() {
    this.setState({ deleteVisible: true });
  }

  deleteOnClose() {
    this.setState({ deleteVisible: false });
  }

  async deleteConfirm() {
    const { medicalConsultationId } = this.state;
    const { history } = this.props;
    this.setState({ deleteLoading: true });
    try {
      await deleteMedicalConsultation(medicalConsultationId!);
      toast('成功刪除醫事顧問');
      this.setState({ deleteVisible: false, deleteLoading: true });
      history.replace('/medical-consultation');
    } catch (err) {
      toast('刪除表格醫事顧問，請重試。');
      this.setState({ deleteVisible: false, deleteLoading: true });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      form,
      loading,
      formLoading,
      medicalConsultationId,
      deleteVisible,
      deleteLoading,
    } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{medicalConsultationId ? '編輯' : '新增'}表格 - JPOA</title>
          <meta name="description" content="" />
        </Helmet>
        <div className={classes.root}>
          <div className={classes.loadingBar}>
            {formLoading ? <LinearProgress color="secondary" /> : null}
          </div>
          {formLoading ? null : (
            <Formik
              initialValues={form}
              validationSchema={Schema}
              onSubmit={values => this.onSubmit(values)}
            >
              {({
                values,
                handleBlur,
                handleChange,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form className={classes.formRoot}>
                  <Paper className={classes.formContainer}>
                    {medicalConsultationId ? (
                      <div className={classes.deleteButtonContainer}>
                        <IconButton onClick={this.deleteOnPress}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ) : null}
                    <div className={classes.formWrapper}>
                      <h2>{medicalConsultationId ? '編輯' : '新增'}醫事顧問</h2>
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="name"
                        label="醫事顧問名稱"
                        placeholder="醫事顧問名稱"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="specialty"
                        label="專科"
                        placeholder="e.g. 牙科"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="description"
                        label="醫事顧問描述"
                        placeholder="醫事顧問描述"
                        width="100%"
                        disableAutoComplete
                        textarea={true}
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="region"
                        label="地區"
                        placeholder="e.g. 灣仔區"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="address"
                        label="地址"
                        placeholder="地址"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="lat"
                        label="緯度 Latitude"
                        placeholder="e.g. 22.3193"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="lng"
                        label="經度 Longitude"
                        placeholder="e.g. 114.1694"
                        width="100%"
                        disableAutoComplete
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="phone"
                        label="電話號碼"
                        placeholder="電話號碼"
                        width="100%"
                        disableAutoComplete
                      />
                      <Button
                        type="submit"
                        style={{ width: '100%', marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        <div>
                          {loading ? (
                            <CircularProgress color="primary" size={22} />
                          ) : (
                            '提交'
                          )}
                        </div>
                      </Button>
                    </div>
                  </Paper>
                </Form>
              )}
            </Formik>
          )}
          <Dialog
            open={deleteVisible}
            handleClose={this.deleteOnClose}
            title="刪除醫事顧問"
            okBtnText="刪除"
            okBtnAction={this.deleteConfirm}
            cancelOnPress={this.deleteOnClose}
            confirmLoading={deleteLoading}
          >
            <div>確定要刪除醫事顧問(編號{medicalConsultationId})?</div>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(MedicalFormPage));
