import Axios from './index';
import { AdminLoginPayload, ListResult, Admin, AdminPara } from '../Models';

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post('/admin/login', para);
  return data;
};

export const getAdminList = async params => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post('/admin', para);
  return data;
};

export const getSingleAdmin = async (adminid: string) => {
  const { data } = await Axios.get(`/admin/${adminid}`);
  return data;
};

export const updateAdmin = async (adminid: string, para: { nick: string }) => {
  const { data } = await Axios.patch(`/admin/${adminid}`, para);
  return data;
};

export const deleteAdmin = async (adminid: string) => {
  const { data } = await Axios.delete(`/admin/${adminid}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin/profile');
  return data;
};

export const resetAdminPassword = async (
  adminid: string,
  para: { password: string },
) => {
  const { data } = await Axios.post<ListResult<Admin>>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};
