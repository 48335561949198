/**
 *
 * Medical Page
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import MedicalConsultationListPage from './components/MedicalConsultationListPage';
// import MedicalFormPage from './components/MedicalFormPage';
import { NotFoundPage } from '../../components/NotFoundPage/Loadable';
import MedicalConsultationFormPage from './components/MedicalConsultationFormPage';

interface Props {}

export const MedicalConsultationPage = memo((props: Props) => {
  return (
    <>
      <Helmet>
        <title>Medical Consultation Page - JPOA</title>
        <meta name="description" content="" />
      </Helmet>
      <Switch>
        <Route
          exact
          path="/medical-consultation"
          component={MedicalConsultationListPage}
        />
        <Route
          exact
          path="/medical-consultation/new"
          component={MedicalConsultationFormPage}
        />
        <Route
          exact
          path="/medical-consultation/edit/:medicalConsultationId"
          component={MedicalConsultationFormPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
});
