import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { PAGE_SIZE } from '../../../constant';

type Props = {
  count: number;
  page: number;
  size?: 'large' | 'medium' | 'small' | undefined;
  onChange: (event: object, page: number) => void;
};

function CustomPagination(props: Props) {
  const { count, page, onChange, size } = props;
  return (
    <Pagination
      variant="outlined"
      shape="rounded"
      color="primary"
      count={isNaN(count) ? 0 : Math.ceil(count / PAGE_SIZE)}
      page={page}
      onChange={onChange}
      size={'medium' || size}
    />
  );
}

export default CustomPagination;
