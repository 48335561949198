import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import MenuItems from './components/MenuItems';
import { getProfile, logout } from 'Services/AdminServices';
import { useSessionContext } from '../../context/SessionContext';

interface PROFILE {
  fullname: string;
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'auto',
  },
  toolbar: {
    paddingRight: theme.spacing(1), // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // position: 'sticky',
    // top: 0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    zIndex: 1299,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'unset !important',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  appLogo: {
    width: 130,
    height: 48,
    objectFit: 'contain',
  },
  appLogoContainer: {
    width: 160,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logoTtitle: {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(51,51,51,0.7)',
    marginLeft: 10,
    marginTop: 2,
  },
  closeIconBtn: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  aciveLinks: {
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
    width: '100%',
    height: '100%',
  },
  footer: {
    height: 54,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTop: '1px solid #eee',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  contentContainer: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  mainContent: {
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
    backgroundColor: '#f4f6f8',
    overflowY: 'unset',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 64px)',
      overflowY: 'auto',
    },
  },
  headerBtn: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 200,
    },
  },
  bacckButton: {
    fontSize: 16,
    fontWeight: 500,
    color: 'white',
  },
  menuBtn: {
    color: 'white',
  },
}));

function Main(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState<PROFILE | any>({});
  // @ts-ignore
  const { setAuthTokens } = useSessionContext();

  useEffect(() => {
    const getAdminProfile = async () => {
      const profileRes = await getProfile();
      setProfile(profileRes);
    };
    getAdminProfile();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const companyLogo = require('../../../Assets/Images/logo.jpg');
  const history = useHistory();
  const logoutOnClick = async () => {
    try {
      await logout();
      setAuthTokens();
    } catch (err) {
      console.log(err);
    }
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const showBar = open || matches;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon className={classes.menuBtn} />
          </IconButton>
          <div className={classes.headerBtn}>
            {history.location.pathname.split('/').length - 1 > 1 ? (
              <Button
                className={classes.bacckButton}
                startIcon={<ArrowLeftIcon />}
                onClick={history.goBack}
              >
                上一頁
              </Button>
            ) : null}
          </div>
          <Button
            className={classes.bacckButton}
            endIcon={<ExitToAppIcon />}
            onClick={logoutOnClick}
          >
            {`${profile && profile.nick ? profile.nick : '-'}, 登出`}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={matches ? 'permanent' : 'temporary'}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !showBar && classes.drawerPaperClose,
          ),
        }}
        open={showBar}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbarIcon}>
          <div className={classes.appLogoContainer}>
            <img src={companyLogo} alt="logo" className={classes.appLogo} />
          </div>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.closeIconBtn}
          >
            <MenuOpenIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuItems setOpen={setOpen} profile={profile} />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.contentContainer}>
          <div className={classes.mainContent}>{props.children}</div>
        </div>
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
