/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

// react toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

import { AdminPage } from './containers/AdminPage/Loadable';
import { LoginPage } from './containers/LoginPage/Loadable';
import { NewsPage } from './containers/NewsPage/Loadable';
import { BannerPage } from './containers/BannerPage/Loadable';
import { WelfarePage } from './containers/WelfarePage/Loadable';
import { EventPage } from './containers/EventPage/Loadable';
import { NewsLetterPage } from './containers/NewsLetterPage/Loadable';
import { DocumentPage } from './containers/DocumentPage/Loadable';
import { MedicalPage } from './containers/MedicalPage/Loadable';
import { NotificationPage } from './containers/NotificationPage/Loadable';
import { MemberPage } from './containers/MemberPage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import ProtectedRoute from './components/ProtectedRoute';
import { SessionContext } from '../app/context/SessionContext';
import { MedicalConsultationPage } from './containers/MedicalConsultationPage';
import { SurveyPage } from './containers/SurveyPage/Loadable';
import { MessagePage } from './containers/MessagePage/Loadable';
import { HGKOPage } from './containers/HGKOPage';

export function App() {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem('isAuth'));
  const setTokens = data => {
    localStorage.setItem('isAuth', JSON.stringify(data));
    setAuthTokens(data);
  };
  return (
    <SessionContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/">
            {authTokens ? <Redirect to="/banner" /> : <Redirect to="login" />}
          </Route>
          <ProtectedRoute path="/banner" component={BannerPage} />
          <ProtectedRoute path="/news" component={NewsPage} />
          <ProtectedRoute path="/newsletter" component={NewsLetterPage} />
          <ProtectedRoute path="/welfare" component={WelfarePage} />
          <ProtectedRoute path="/event" component={EventPage} />
          <ProtectedRoute path="/document" component={DocumentPage} />
          <ProtectedRoute path="/medical" component={MedicalPage} />
          <ProtectedRoute path="/survey" component={SurveyPage} />
          <ProtectedRoute
            path="/medical-consultation"
            component={MedicalConsultationPage}
          />
          <ProtectedRoute path="/notification" component={NotificationPage} />
          <ProtectedRoute path="/hgko" component={HGKOPage} />
          <ProtectedRoute path="/member" component={MemberPage} />
          <ProtectedRoute path="/message" component={MessagePage} />
          <ProtectedRoute path="/admin" component={AdminPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </SessionContext.Provider>
  );
}
