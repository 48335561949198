import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage } from '../../components/NotFoundPage/Loadable';
import HGKOListPage from './components/HGKOListPage';
import HGKOUploadPage from './components/HGKOUploadPage';

interface Props {}

export const HGKOPage = memo((props: Props) => {
  return (
    <>
      <Helmet>
        <title>HGKO Page - JPOA</title>
        <meta name="description" content="" />
      </Helmet>
      <Switch>
        <Route exact path="/hgko" component={HGKOListPage} />
        <Route exact path="/hgko/upload" component={HGKOUploadPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
});
