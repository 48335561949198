import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Avatar,
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import moment from 'moment';

import { IMAGE_URL, SECURE_IMAGE_URL } from '../../../constant';
import { Column } from '../../../Models/Table';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    flex: 1,
  },
  imageContainer: {
    width: 100,
    height: 40,
    borderRadius: 4,
    overflow: 'hidden',
    border: '1px solid #ededed',
  },
  previewImage: {
    width: 100,
    height: 40,
    objectFit: 'cover',
  },
  funcionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  imageCol: {
    width: 140,
  },
}));

type Props<T> = {
  column: Column[];
  data: T[];
  rowKey: string;
  renderRowButtons?: (item: T) => React.ReactNode;
};

const CustomTable = <T extends object>(props: Props<T>) => {
  const classes = useStyles();
  const { column, data, rowKey, renderRowButtons } = props;
  return (
    <TableContainer classes={{ root: classes.tableRoot }} id="TableContainer">
      <Table classes={{ root: classes.tableRoot }} stickyHeader>
        <TableHead>
          <TableRow>
            {column.map(col => (
              <TableCell key={col.field}>{col.name}</TableCell>
            ))}
            {renderRowButtons ? <TableCell align="right"> </TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => {
            return (
              <TableRow key={item[rowKey]}>
                {column.map(col => {
                  if (col.isAvatar) {
                    if (item[col.field]) {
                      return (
                        <TableCell key={col.field} className={classes.imageCol}>
                          <Avatar
                            alt="User"
                            src={SECURE_IMAGE_URL + item[col.field]}
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={col.field}>
                        <Avatar>
                          <AccountCircleOutlinedIcon />
                        </Avatar>
                      </TableCell>
                    );
                  }
                  if (col.isImage) {
                    if (item[col.field]) {
                      return (
                        <TableCell key={col.field} className={classes.imageCol}>
                          <div className={classes.imageContainer}>
                            <img
                              src={IMAGE_URL + item[col.field]}
                              alt="preview"
                              className={classes.previewImage}
                            />
                          </div>
                        </TableCell>
                      );
                    }
                    return <TableCell key={col.field}>沒有圖片</TableCell>;
                  }
                  if (col.isSecureImage) {
                    if (item[col.field]) {
                      return (
                        <TableCell key={col.field} className={classes.imageCol}>
                          <div className={classes.imageContainer}>
                            <img
                              src={SECURE_IMAGE_URL + item[col.field]}
                              alt="preview"
                              className={classes.previewImage}
                            />
                          </div>
                        </TableCell>
                      );
                    }
                    return <TableCell key={col.field}>沒有圖片</TableCell>;
                  }
                  if (col.isLog) {
                    if (item[col.field] && item[col.field].length > 0) {
                      return (
                        <TableCell key={col.field} className={classes.imageCol}>
                          {moment(item[col.field][0].createdAt).format(
                            'DD-MM-YYYY HH:mm:ss',
                          )}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={col.field} className={classes.imageCol}>
                        -
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={col.field} style={{ maxWidth: 500 }}>
                      {item[col.field] || '-'}
                    </TableCell>
                  );
                })}
                {renderRowButtons ? (
                  <TableCell align="right">{renderRowButtons(item)}</TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
