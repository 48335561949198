import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _ from 'lodash';

// material core
import {
  LinearProgress,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';

// material icon
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

//material style
import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

// custom items
import Table from '../../../components/CustomTable';
import Pagination from '../../../components/Pagination';
import { Category, MedicalConsultation, OptionItem } from '../../../../Models';
import { MEDICAL_CONSULTATION_TABLE_COLUMN } from '../../../../utils/common';
import { PAGE_SIZE } from '../../../../constant';
import {
  getMedicalConsultationList,
  getMedicalConsultationRegionList,
  getMedicalConsultationSpecialtyList,
} from 'Services/MedicalConsultationService';
import SelectFilter from 'app/components/Filter/SelectFilter';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  paper: {
    minWidth: 900,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    height: 'calc(100vh - 64px - 38px)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(2, 0, 3, 0),
      height: 'unset',
      borderRadius: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    // padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  toolRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  links: {
    textDecoration: 'none',
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  listItemRoot: {
    width: '100%',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  imageContainer: {
    width: 100,
    height: 40,
    borderRadius: 4,
    overflow: 'hidden',
  },
  previewImage: {
    width: 100,
    height: 40,
    objectFit: 'cover',
  },
  funcionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 2, 1),
    },
  },
  titleTextCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  reminderText: {
    fontSize: 12,
    fontWeight: 400,
    color: '#444444',
    // fontStyle: 'italic',
  },
  titleText: {
    marginBottom: 0,
  },
  regionFilter: {
    marginLeft: 8,
    width: 200,
  },
});

type Props = WithStyles<typeof styles>;
type State = {
  categoryList: Category[];
  medicalList: MedicalConsultation[];
  count: number;
  page: number;
  pageLoading: boolean;
  deleteUserVisible: boolean;
  confirmLoading: boolean;
  selectedItem: {};
  profile: {};
  loading: boolean;
  searchValue: string;
  filter: {
    limit: number;
    offset: number;
    q?: string;
    asc?: keyof MedicalConsultation;
    desc?: keyof MedicalConsultation;
    region?: string;
    specialty?: string;
  };
  regions: OptionItem[];
  specialties: OptionItem[];
};

type TParams = {};

class MedicalListPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      categoryList: [],
      medicalList: [],
      count: 0,
      page: 1,
      pageLoading: false,
      deleteUserVisible: false,
      confirmLoading: false,
      selectedItem: {},
      profile: {},
      loading: true,
      filter: {
        limit: PAGE_SIZE,
        offset: 0,
        desc: 'createdAt',
      },
      searchValue: '',
      regions: [],
      specialties: [],
    };
    this.pageOnChange = this.pageOnChange.bind(this);
    this.searchTextOnChange = this.searchTextOnChange.bind(this);
    this.setSearch = _.debounce(this.setSearch, 500).bind(this);
    this.getRegions = this.getRegions.bind(this);
    this.getSpecialties = this.getSpecialties.bind(this);
  }
  componentDidMount() {
    this.getFilterValuesFromURL();
    this.getRegions();
    this.getSpecialties();
  }

  componentDidUpdate(prevProps) {
    const preValues = queryString.parse(prevProps.location.search);
    const values = queryString.parse(this.props.location.search);
    if (!_.isEqual(preValues, values)) {
      this.getFilterValuesFromURL();
    }
  }

  getFilterValuesFromURL() {
    const { location } = this.props;
    const { search } = location;
    const { filter } = this.state;
    const tempFilter = { ...filter };
    const params = queryString.parse(search);
    const page =
      params && params.page ? parseInt(params.page as string, 10) : 1;
    const q = params && params.q ? (params.q as string) : undefined;
    const region =
      params && params.region ? (params.region as string) : undefined;
    const specialty =
      params && params.specialty ? (params.specialty as string) : undefined;
    if (q) {
      tempFilter.q = q;
    } else {
      delete tempFilter.q;
    }
    if (region) {
      tempFilter.region = region;
    } else {
      delete tempFilter.region;
    }
    if (specialty) {
      tempFilter.specialty = specialty;
    } else {
      delete tempFilter.specialty;
    }
    this.setState(
      {
        filter: tempFilter,
        page,
        searchValue: q ? (q as string) : '',
      },
      () => this.getMedicalList(),
    );
  }

  async getMedicalList() {
    this.setState({ pageLoading: true });
    const { filter, page } = this.state;
    const temp = {
      ...filter,
      offset: (page - 1) * PAGE_SIZE,
    };
    try {
      const medicalRes = await getMedicalConsultationList(temp);
      this.setState({
        count: medicalRes.count,
        medicalList: medicalRes.rows,
        pageLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ pageLoading: false });
    }
  }

  async getRegions() {
    try {
      const regionRes = await getMedicalConsultationRegionList();
      this.setState({
        regions: regionRes.map(res => ({
          label: res.region,
          value: res.region,
        })),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getSpecialties() {
    try {
      const specialtiesRes = await getMedicalConsultationSpecialtyList();
      this.setState({
        specialties: specialtiesRes.map(res => ({
          label: res.specialty,
          value: res.specialty,
        })),
      });
    } catch (err) {
      console.log(err);
    }
  }

  pageOnChange(event: object, page: number) {
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    currentUrlParams.set('page', page + '');
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ page }, () => {
      history.push(url);
    });
  }

  searchTextOnChange(e: React.ChangeEvent<{ value: unknown }>) {
    this.setState({ searchValue: e.target.value as string });
    this.setSearch();
  }

  setSearch() {
    const { location, history } = this.props;
    const { searchValue } = this.state;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.set('q', searchValue);
    const url = `${location.pathname}?${queryParams.toString()}`;
    history.push(url);
  }

  renderData(data: MedicalConsultation[]) {
    const temp: any = [];
    data.forEach(item => {
      temp.push({
        ...item,
        date: moment(item.createdAt).format('DD-MM-YYYY'),
      });
    });
    return temp;
  }

  render() {
    const { classes } = this.props;
    const {
      pageLoading,
      medicalList,
      count,
      page,
      searchValue,
      regions,
      specialties,
    } = this.state;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>醫事顧問 - JPOA</title>
        </Helmet>
        <div className={classes.loadingBar}>
          {pageLoading ? <LinearProgress color="secondary" /> : null}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <div className={classes.titleTextCol}>
              <h2 className={classes.titleText}>醫事顧問</h2>
            </div>
            <Link to="/medical-consultation/new" className={classes.links}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                新增醫事顧問
              </Button>
            </Link>
          </div>
          <div className={classes.filterRow}>
            <div className={classes.regionFilter}>
              <FormControl className={classes.textFormControl}>
                <InputLabel>顧問名稱</InputLabel>
                <Input
                  placeholder="顧問名稱"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchOutlinedIcon className={classes.searchIcon} />
                    </InputAdornment>
                  }
                  value={searchValue}
                  onChange={this.searchTextOnChange}
                />
              </FormControl>
            </div>

            <div className={classes.regionFilter}>
              <SelectFilter label="地區" name="region" options={regions} />
            </div>
            <div className={classes.regionFilter}>
              <SelectFilter
                label="專科"
                name="specialty"
                options={specialties}
              />
            </div>
          </div>
          <Table
            column={MEDICAL_CONSULTATION_TABLE_COLUMN}
            data={this.renderData(medicalList)}
            rowKey="medicalConsultationid"
            renderRowButtons={(item: MedicalConsultation) => {
              return (
                <Link
                  to={`/medical-consultation/edit/${item.medicalConsultationid}`}
                >
                  <Button>
                    <EditIcon style={{ color: 'rgba(0,0,0,0.4)' }} />
                  </Button>
                </Link>
              );
            }}
          />
          <div className={classes.paginationContainer}>
            <Pagination
              count={count}
              page={page}
              onChange={this.pageOnChange}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(MedicalListPage));
