import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router-dom';

//material icons
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';

// material core
import { LinearProgress, CircularProgress, Paper } from '@material-ui/core';

import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

// constom items
import { LocalFile } from '../../../../Models';
import { importHGKO } from 'Services/HGKOServices';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
  },
  formContainer: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
    margin: 16,
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 600,
    // height: 500,
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
  chipRow: {
    width: '100%',
    padding: `${theme.spacing(1)}px 0px !important`,
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  dropzoneWrapper: {
    width: '100%',
    height: 225,
  },
  dropzoneContainer: {
    width: '100%',
    height: 200,
    border: '1px dotted rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleBtnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  htmlContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0px 0 5px 0',
  },
  coverImageContainer: {
    width: '100%',
    height: 200,
    borderRadius: 4,
    marginBottom: 20,
    position: 'relative',
  },
  coverImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 4,
  },
  coverImageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  closeBtnContainer: {
    position: 'absolute',
    top: -25,
    right: -25,
  },
  noteIcon: {
    fontSize: 60,
    color: 'rgb(204, 204, 204)',
  },
  dropzoneText: {
    fontSize: 26,
    color: 'rgb(204, 204, 204)',
    margin: '5px 0px 2px 0px',
  },
  dropzoneSubText: {
    fontSize: 12,
    color: 'rgb(204, 204, 204)',
  },
  csvContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  csvWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  csvTextRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '10px 0px 20px 0px',
  },
  csvIcon: {
    fontSize: 50,
    color: '#34495e',
  },
  csvTextCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '0px 0px 0px 10px',
  },
  pdfText: {
    width: '400px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pdfSubText: {
    width: '200px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  deleteButtonContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  imagesGridList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: '#ffffff',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imageDeleteIcon: {
    color: '#fffff',
  },
});

type FormItem = {
  csvFile: any | LocalFile[];
};

const FORM_ITEM = {
  csvFile: null,
};

const Schema = Yup.object().shape({
  csvFile: Yup.mixed().required('請選擇CSV'),
});

type Props = WithStyles<typeof styles>;
type State = {
  form: FormItem;
  loading: boolean;
  formLoading: boolean;
  newsletterid: number | null;
  deleteVisible: boolean;
  deleteLoading: boolean;
  csvLoading: boolean;
};

type TParams = {
  newsletterid: string;
};

class HGKOUploadPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      form: { ...FORM_ITEM },
      loading: false,
      formLoading: false,
      newsletterid: null,
      deleteVisible: false,
      deleteLoading: false,
      csvLoading: false,
    };
  }

  onSubmit(values) {
    console.log(values);
  }

  pdfOnDelete(setFieldValue) {
    setFieldValue('csvFile', null);
  }

  async csvOnDrop(file, setFieldValue) {
    const { history } = this.props;
    if (file[0].size > 1024 * 50000) {
      toast('.csv檔案不可大於50MB');
      return;
    }
    if (file[0].type === 'text/csv') {
      this.setState({ csvLoading: true });
      const csvData = new FormData();
      csvData.append('file', file[0]);
      try {
        await importHGKO(csvData);
        toast('成功上載HGKO');
        history.push('/hgko');
      } catch (err) {
        toast('上載失敗，請再試一次');
        setFieldValue('csvFile', null);
      }
      this.setState({ csvLoading: false });
    } else {
      this.setState({ csvLoading: false });
      toast('只接受.csv檔案');
      return;
    }
  }

  render() {
    const { classes } = this.props;
    const { form, formLoading, csvLoading } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>上載HGKO - JPOA</title>
          <meta name="description" content="" />
        </Helmet>
        <div className={classes.root}>
          <div className={classes.loadingBar}>
            {formLoading ? <LinearProgress color="secondary" /> : null}
          </div>
          {formLoading ? null : (
            <Formik
              initialValues={form}
              validationSchema={Schema}
              onSubmit={values => this.onSubmit(values)}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.formRoot}>
                  <Paper className={classes.formContainer}>
                    <div className={classes.formWrapper}>
                      <h2>上載HGKO</h2>

                      <div
                        className={classes.csvContainer}
                        style={values.csvFile ? {} : { height: 235 }}
                      >
                        <div className={classes.dropzoneWrapper}>
                          <Dropzone
                            onDrop={file => this.csvOnDrop(file, setFieldValue)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className={classes.dropzoneContainer}
                                style={{ marginTop: 10 }}
                              >
                                {csvLoading ? (
                                  <CircularProgress color="primary" size={22} />
                                ) : (
                                  <div
                                    {...getRootProps()}
                                    className={classes.dropzone}
                                  >
                                    <input {...getInputProps()} />
                                    <NoteAddOutlinedIcon
                                      className={classes.noteIcon}
                                    />
                                    <div className={classes.dropzoneText}>
                                      CSV檔案
                                    </div>
                                    <div className={classes.dropzoneSubText}>
                                      點擊或拉動CSV檔案到此處
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Dropzone>
                          <ErrorMessage name="csvFile">
                            {msg => <div className={classes.errmsg}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(HGKOUploadPage));
