import Axios from '../Services';
import { MedicalConsultation, ListResult } from '../Models';

export const getMedicalConsultationList = async params => {
  const { data } = await Axios.get<ListResult<MedicalConsultation>>(
    '/medicalConsultation',
    {
      params,
    },
  );
  return data;
};

// export const getMedicalCategoryList = async () => {
//   const { data } = await Axios.get<MedicalCategory[]>('/medical/category');
//   return data;
// };

export const getSingleMedicalConsultation = async (id: string) => {
  const { data } = await Axios.get<MedicalConsultation>(
    `/medicalConsultation/${id}`,
  );
  return data;
};

export interface CreateMedicalConsultationParams {
  name: string;
  description: string;
  lng: number;
  lat: number;
  phone: string;
  specialty: string;
  region: string;
  address: string;
}

export const createMedicalConsultation = async (
  param: CreateMedicalConsultationParams,
) => {
  const { data } = await Axios.post<any>('/medicalConsultation', param);
  return data;
};

export const updateMedicalConsultation = async (
  medicalConsultationId: string | number,
  param: CreateMedicalConsultationParams,
) => {
  const { data } = await Axios.patch<any>(
    `/medicalConsultation/${medicalConsultationId}`,
    param,
  );
  return data;
};

export const deleteMedicalConsultation = async (
  medicalConsultationId: string | number,
) => {
  const { data } = await Axios.delete<any>(
    `/medicalConsultation/${medicalConsultationId}`,
  );
  return data;
};

export const getMedicalConsultationRegionList = async () => {
  const { data } = await Axios.get<{ region: string }[]>(
    `/medicalConsultation/region`,
  );
  return data;
};

export const getMedicalConsultationSpecialtyList = async () => {
  const { data } = await Axios.get<{ specialty: string }[]>(
    `/medicalConsultation/specialty`,
  );
  return data;
};
