import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

// material core
import {
  LinearProgress,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
} from '@material-ui/core';

// material icon
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//material style
import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

// custom items
import Table from '../../../components/CustomTable';
import Pagination from '../../../components/Pagination';
import { getHGKOList } from '../../../../Services/HGKOServices';
import { HGKO } from '../../../../Models';
import { HGKO_TABLE_COLUMN } from '../../../../utils/common';
import { API_URL, PAGE_SIZE } from '../../../../constant';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  paper: {
    minWidth: 900,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    height: 'calc(100vh - 64px - 38px)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(2, 0, 3, 0),
      height: 'unset',
      borderRadius: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    // padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  toolRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  links: {
    textDecoration: 'none',
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  listItemRoot: {
    width: '100%',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  imageContainer: {
    width: 100,
    height: 40,
    borderRadius: 4,
    overflow: 'hidden',
  },
  previewImage: {
    width: 100,
    height: 40,
    objectFit: 'cover',
  },
  funcionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 2, 1),
    },
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
});

type Props = WithStyles<typeof styles>;
type State = {
  hgkoList: HGKO[];
  count: number;
  page: number;
  pageLoading: boolean;
  confirmLoading: boolean;
  loading: boolean;
  searchValue: string;
  filter: {
    limit: number;
    offset: number;
    pcode?: string;
    asc?: keyof HGKO;
    desc?: keyof HGKO;
  };
};

type TParams = {};

class HGKOListPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      hgkoList: [],
      count: 0,
      page: 1,
      pageLoading: false,
      confirmLoading: false,
      loading: true,
      filter: {
        limit: PAGE_SIZE,
        offset: 0,
        pcode: undefined,
        desc: 'hgkoid',
      },
      searchValue: '',
    };
    this.searchTextOnChange = this.searchTextOnChange.bind(this);
    this.setSearch = _.debounce(this.setSearch, 500).bind(this);
    this.pageOnChange = this.pageOnChange.bind(this);
  }
  componentDidMount() {
    this.getFilterValuesFromURL();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const preValues = queryString.parse(prevProps.location.search);
    const values = queryString.parse(location.search);
    if (!_.isEqual(preValues, values)) {
      this.getFilterValuesFromURL();
    }
  }

  getFilterValuesFromURL() {
    const { location } = this.props;
    const { search } = location;
    const { filter } = this.state;
    const tempFilter = { ...filter };
    const params = queryString.parse(search);
    const tempPcode = (params.pcode as string) || undefined;
    const page =
      params && params.page ? parseInt(params.page as string, 10) : 1;
    tempFilter.pcode = tempPcode;
    this.setState(
      { filter: tempFilter, page, searchValue: tempPcode ?? '' },
      () => this.getHGKOList(),
    );
  }

  async getHGKOList() {
    this.setState({ pageLoading: true });
    const { filter, page } = this.state;
    const temp = {
      ...filter,
      offset: (page - 1) * PAGE_SIZE,
    };
    try {
      const hgkoRes = await getHGKOList(temp);
      this.setState({
        count: hgkoRes.count,
        hgkoList: hgkoRes.rows,
        pageLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ pageLoading: false });
    }
  }

  pageOnChange(event: object, page: number) {
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    currentUrlParams.set('page', page + '');
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ page }, () => {
      history.push(url);
    });
  }

  renderData(data: HGKO[]) {
    const temp: any = [];
    data.forEach(item => {
      temp.push({
        ...item,
        date: moment(item.createdAt).format('DD-MM-YYYY'),
      });
    });
    return temp;
  }

  searchTextOnChange(e: React.ChangeEvent<{ value: unknown }>) {
    this.setState({ searchValue: e.target.value as string });
    this.setSearch();
  }

  setSearch() {
    const { location, history } = this.props;
    const { searchValue } = this.state;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.set('pcode', searchValue);
    const url = `${location.pathname}?${queryParams.toString()}`;
    history.push(url);
  }

  render() {
    const { classes } = this.props;
    const { pageLoading, hgkoList, count, page, searchValue } = this.state;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>HGKO - JPOA</title>
        </Helmet>
        <div className={classes.loadingBar}>
          {pageLoading ? <LinearProgress color="secondary" /> : null}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <h2>HGKO</h2>
            <div className={classes.buttonRow}>
              <a
                href={`${API_URL}/v1/hgko/hgkoTemplate`}
                className={classes.links}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                >
                  下載HGKO範本
                </Button>
              </a>
              <Link to="/hgko/upload" className={classes.links}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                >
                  上載HGKO
                </Button>
              </Link>
            </div>
          </div>
          <div className={classes.filterRow}>
            <div className={classes.regionFilter}>
              <FormControl className={classes.textFormControl}>
                <InputLabel>Pcode</InputLabel>
                <Input
                  placeholder="Pcode"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchOutlinedIcon className={classes.searchIcon} />
                    </InputAdornment>
                  }
                  value={searchValue}
                  onChange={this.searchTextOnChange}
                />
              </FormControl>
            </div>
          </div>
          <Table
            column={HGKO_TABLE_COLUMN}
            data={this.renderData(hgkoList)}
            rowKey="hgkoid"
          />
          <div className={classes.paginationContainer}>
            <Pagination
              count={count}
              page={page}
              onChange={this.pageOnChange}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(HGKOListPage));
