import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

// Initialize languages
import './locales/i18n';

// create material ui style sheet
import getPageContext from './utils/getPageContext';

const store = configureAppStore();
const getPageThemeContext = getPageContext();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
Bugsnag.start({
  apiKey: '62b62cd6beff3c4ff2c0af993f3f6396',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: '1.0.0',
  autoTrackSessions: false,
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <MuiThemeProvider theme={getPageThemeContext.theme}>
            <App />
          </MuiThemeProvider>
        </React.StrictMode>
      </HelmetProvider>
    </Provider>
  </ErrorBoundary>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
