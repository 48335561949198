import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import { OptionItem } from 'Models';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  selectRoot: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
}));

interface Category {
  category: string;
  inputValue?: string;
}

type Props = {
  name: string;
  label: string;
  options: OptionItem[];
};

function SelectFilter(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState('all');
  const { options, name, label } = props;

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params && params[name]) {
      setValue(params[name] as string);
    } else {
      setValue('all');
    }
  }, [location.search, name]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const queryParams = new URLSearchParams(location.search);
    if (event.target.value !== 'all') {
      queryParams.set('page', '1');
      queryParams.set(name, event.target.value as string);
    } else if (event.target.value === 'all') {
      queryParams.set('page', '1');
      queryParams.delete(name);
    }
    const url = `${location.pathname}?${queryParams.toString()}`;
    history.push(url);
    setValue(event.target.value as string);
  };
  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} label={label}>
        <MenuItem value="all">所有</MenuItem>
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectFilter;
