/**
 *
 * Asynchronously loads the component for MemberPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MemberPage = lazyLoad(
  () => import('./index'),
  module => module.MemberPage,
);
