import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  rootNormal: {
    minWidth: 600,
    position: 'relative',
    zIndex: 1501,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
    },
  },
  root: {
    minWidth: '88vw',
    minHeight: '88vh',
    position: 'relative',
    zIndex: 1501,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
      height: '100%',
      margin: 0,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
  loadingRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentRoot: {
    padding: theme.spacing(0, 3),
    backgroundColor: 'white',
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
  okBtnText?: string;
  okBtnAction?: () => void;
  closeOnPress?: () => void;
  cancelOnPress?: () => void;
  confirmLoading?: boolean;
  cancelText?: string;
  cancelDisable?: boolean;
  okDisable?: boolean;
  width?: number;
  maxWidth?: number;
  takeUpAll?: boolean;
  setFullWidth?: boolean;
};

function DialogContainer(props: Props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    title,
    children,
    okBtnText,
    okBtnAction,
    closeOnPress,
    cancelOnPress,
    confirmLoading,
    cancelText,
    cancelDisable,
    okDisable,
    takeUpAll,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={
        takeUpAll ? { paper: classes.root } : { paper: classes.rootNormal }
      }
    >
      <DialogTitle>{title || 'Title'}</DialogTitle>
      <DialogContent className={classes.contentRoot}>
        {confirmLoading ? (
          <div className={classes.loadingRow}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          children
        )}
      </DialogContent>
      {okBtnAction ? (
        <DialogActions>
          <Button
            onClick={cancelOnPress || handleClose}
            color="primary"
            disabled={confirmLoading || cancelDisable}
          >
            {cancelText || '取消'}
          </Button>
          <Button
            onClick={okBtnAction || handleClose}
            color="secondary"
            disabled={confirmLoading || okDisable}
          >
            {okBtnText || '確定'}
          </Button>
        </DialogActions>
      ) : null}
      {closeOnPress ? (
        <div className={classes.closeBtn} onClick={closeOnPress}>
          <CloseIcon />
        </div>
      ) : null}
    </Dialog>
  );
}

export default DialogContainer;
