import * as React from 'react';
import { Redirect, Route } from 'react-router';

import { useSessionContext } from '../../context/SessionContext';
import Main from '../MainLayout';

function ProtectedRoute({ component: Component, ...rest }) {
  // @ts-ignore
  const { authTokens } = useSessionContext();
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        authTokens ? (
          <Main>
            <Component {...props} />
          </Main>
        ) : (
          <Redirect
            //@ts-ignore
            to={{ pathname: '/login', state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
